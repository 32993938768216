<template>
  <b-navbar class="navbar-top" toggleable="lg" :class="{ 'navbar-collapse-show': mainMenuVisible, 'navbar-user-logged-in': isAuthenticated, 'no-brand': !headerVisible }">
    <div class="navbar-container-lg">
      <b-navbar-brand :class="{'hide-brand': !headerVisible}">
        <b-link class :href="logoUrl" v-if="logoUrl">
          <b-img class="brand-logo" :src="brandLogo" :alt="brandAlternative"/>
        </b-link>
        <localized-link class :to="appLocalizeRoute({ name: 'home' })" v-else>
            <b-img class="brand-logo" :src="brandLogo" :alt="brandAlternative"/>
        </localized-link>
      </b-navbar-brand>
      <div class="nav-user" v-if="isAuthenticated">
        <span class="user-account-info nav-profile-image" @click="toggleNavbarForceOpen" :title="activeAccount.firstName + ' ' + activeAccount.lastName" v-if="profileImage">
          <span :style="{'background-image': `url(${profileImage})`}" class="ba-image-fill"></span>
        </span>
        <span class="user-account-info" @click="toggleNavbarForceOpen" :title="activeAccount.firstName + ' ' + activeAccount.lastName" v-else>
          <font-awesome-icon class="nav-icon" size="2x" icon="user"/>
        </span>
        <span class="user-logout" @click="logout()">
          <font-awesome-icon class="nav-icon" size="2x" icon="sign-out-alt"/>
          <span class="sr-only">{{ $t('action_logout') }}</span>
        </span>
      </div>
      <b-navbar-toggle class="nav-user" target="nav-collapse-user" ref="toggle-user" v-else>
        <span>
          <font-awesome-icon class="nav-icon" size="2x" icon="user"/>
        </span>
      </b-navbar-toggle>
      <b-navbar-toggle target="nav-collapse-main" ref="toggle-main" v-if="isAuthenticated">
        <span class="icon-bar top-bar"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </b-navbar-toggle>
      <b-collapse v-model="mainMenuVisible" id="nav-collapse-main" is-nav v-if="isAuthenticated">
        <ba-mobile-navbar-menu :message-count="messageCount" :profile-image="profileImage" />
        <div class="container nav-container" v-if="headerVisible" >
          <ba-navigation/>
        </div>
        <div class="navbar-top-bar">
          <div class="container">
            <ba-language-select/>
            <ba-topbar-menu :message-count="messageCount" :profile-image="profileImage"/>
          </div>
        </div>
      </b-collapse>
      <div class="navbar-collapse collapse show" id="nav-collapse-main" v-else>
        <div class="navbar-top-bar no-auth">
          <div class="container">
            <ba-language-select/>
            <ba-topbar-menu :message-count="0" :profile-image="false"/>
          </div>
        </div>
      </div>
      <b-collapse v-model="userMenuVisible" id="nav-collapse-user" is-nav v-if="!isAuthenticated">
        <ba-login-form class="d-lg-none" context="mobile" id="nav-login-form-mobile" @close="toggleNavbarForceClose"/>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
  import BaTopbarMenu from '@/components/partials/Header/BaTopbarMenu';
  import BaLoginForm from '@/components/partials/Header/BaLoginForm';
  import BaLanguageSelect from '@/components/partials/Header/BaLanguageSelect';
  import BaNavigation from '@/components/partials/Header/BaNavigation';
  import BaMobileNavbarMenu from '@/components/partials/Header/BaMobileNavbarMenu';

  import { mapActions, mapGetters } from 'vuex';

  import Config from '@/services/config';
  import { getThreads } from '@/api/messaging';
  import { getApplicantProfileImage } from '@/api/applicant';

  export default {
    components: {
      BaTopbarMenu,
      BaLoginForm,
      BaLanguageSelect,
      BaMobileNavbarMenu,
      BaNavigation,
    },
    data: () => ({
      mainMenuVisible: false,
      userMenuVisible: false,
      messageCount: 0,
      profileImage: false,
    }),
    created: function () {
      this.loadProfileImage();
    },
    computed: {
      ...mapGetters(['activeAccount', 'isAuthenticated']),
      logoUrl: function () {
        //const routeName = this.$route.name;
        // if (routeName === 'home' && Config.logoLink) {
        //   return Config.logoLink;
        // }
        return null;
      },
      brandLogo: function () {
        return Config.projectLogo;
      },
      brandAlternative: function () {
        return Config.projectName;
      },
      headerVisible: function () {
        return true;//this.$route.name !== 'home';
      },
    },
    mounted: function () {
      if (this.isAuthenticated) {
        this.updateMessagesCount();
      } else {
        this.mainMenuVisible = true;
      }
    },
    methods: {
      ...mapActions(['logout']),
      updateMessagesCount () {
        const self = this;
        getThreads()
          .then(({ data: response }) => {
            this.messageCount = 0;
            response.forEach(function (thread) {
              if (thread.unreadMessages) {
                self.messageCount += thread.unreadMessages;
              }
            });
          })
        ;
      },
      toggleNavbarForceOpen () {
        this.mainMenuVisible = true;
      },
      toggleNavbarForceClose () {
        this.mainMenuVisible = false;
        this.userMenuVisible = false;
      },
      loadProfileImage: function () {
        if (this.activeAccount && this.activeAccount.hasProfileImage && this.activeAccount.applicantId) {
          getApplicantProfileImage(this.activeAccount.applicantId)
            .then((response) => {
              if (response && response.data) {
                this.profileImage = response.data;
              }
            })
          ;
        } else {
          this.profileImage = false;
        }
      },
    },
    watch: {
      isAuthenticated: function (isAuthenticated) {
        if (isAuthenticated) {
          this.updateMessagesCount();
        }
      },
      $route () {
        if (this.isAuthenticated) {
          this.updateMessagesCount();
        }
      },
      mainMenuVisible: function (mainMenuVisible) {
        if (mainMenuVisible && this.userMenuVisible) {
          this.userMenuVisible = false;
        }
      },
      userMenuVisible: function (userMenuVisible) {
        if (userMenuVisible && this.mainMenuVisible && this.isAuthenticated) {
          this.mainMenuVisible = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "../style/_init-vars-functions-mixins";

  .navbar-top {
    display: block;

    @include media-breakpoint-up(lg) {
      padding-top: 10px;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        background: $gray-300;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        z-index: 0;
      }
    }
  }
  .navbar-container-lg {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 960px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1200px;
    }
  }
  .navbar-brand {
    padding-left: 15px;
    padding-bottom: 21px;
    img {
      width: 150px;
      height: auto;
    }
    &.hide-brand {
      opacity: 0;
      visibility: hidden;
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      z-index: 13;
      padding: 0 0 15px 0;
      &.hide-brand {
        display: none;
      }
      img {
        max-width: 255px;
        max-height: 68px;
      }
    }
  }
  .nav-profile-image {
    flex: 0 0 48px;
    border-radius: 50%;
    display: block;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    overflow: hidden;
  }
  .nav-user {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: 23px;
    transition: opacity .2s linear;

    span {
      cursor: pointer;
      display: none;
      margin-left: 26px;
      &:first-child {
        margin-left: 0;
      }
    }
    .nav-icon {
      color: $ba-brown-grey;
    }
    @media (min-width: 320px) {
      .user-logout {
        display: block;
      }
    }
    @media (min-width: 400px) {
      .user-account-info {
        display: block;
        .ba-image-fill {
          display: block;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      flex: 0 1 auto;
    }
  }
  .navbar-user-logged-in {
    .navbar-collapse {
      margin-top: -100px;
      position: relative;
      z-index: 10;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        // Needed so top nav can have full width!
        position: static;
      }
    }
    &.no-brand {
      .navbar-collapse {
        margin-top: -60px;
        @include media-breakpoint-up(lg) {
          margin-top: 0;
        }
      }
    }

    .navbar-brand {
      transition: opacity .2s linear;
    }
    &.navbar-collapse-show {
      .navbar-brand {
        opacity: 0;
        @include media-breakpoint-up(lg) {
          transition: none;
          opacity: 1;
        }
      }
    }
  }
  .navbar-top-bar {
    background-color: $ba-bg-primary;
    font-weight: $ba-font-weight-medium;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(sm) {
      .b-nav-dropdown.show {
        margin-right: 15px;
        margin-bottom: 15px;
        flex: 1 1 100%;
        max-width: 400px;
      }
    }
    @include media-breakpoint-down(md) {
      > .container {
        padding-right: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 12;
      &.no-auth {
        top: 10px;
      }
      > .container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 10px 0 0;
        .topbar-menu-container {
          order: 0;
        }
        .language-select-container {
          order: 2;
        }
      }
    }
  }
  // Override default navbar-nav styles
  .navbar-light .navbar-top-bar {
    .navbar-nav {
      .nav-link {
        color: $ba-link-color-light;
        transition: color .2s linear;

        &:hover,
        &:focus,
        &.active {
          color: $ba-text-color-light;
        }
      }
      .active > .nav-link {
        color: $ba-text-color-light;
      }
    }
  }
  .nav-container {
    @media (min-width: 360px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-up(sm) {
      padding-left: 45px;
      padding-right: 45px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
  .nav-user .fa-2x {
    font-size: 2rem;
  }
  @media print {
    .navbar-top {
      padding: 0;
    }
    .nav-user,
    .navbar-top-bar,
    .navbar-toggler,
    #nav-collapse-main {
      display: none;
    }
  }
</style>

<i18n src="@/translations/common.i18n.yaml" lang="yaml"/>
