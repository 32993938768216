<template>
  <div class="user-menu">
    <div class="container nav-container">
      <div class="user-info">
        <div class="user-icon nav-profile-image" v-if="profileImage">
          <div :style="{'background-image': `url(${profileImage})`}" class="ba-image-fill"></div>
        </div>
        <div class="user-icon" v-else>
          <font-awesome-icon size="2x" icon="building"/>
        </div>
        <div class="user-data">
          <h5>{{ activeAccount.firstName }} {{ activeAccount.lastName }}</h5>
          <div v-if="isCompanyAccount">{{ activeAccount.displayName }}</div>
          <b-link @click="logout()">{{ $t('components.navigation.action_logout') }}</b-link>
        </div>
      </div>
      <b-navbar-nav class="user-links">
        <b-nav-item :to="appLocalizeRoute({name: 'apply-initiative'})" v-if="isApplicantAccount && applicantSettings.allowMultipleApplications">
          <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
          {{ $t('components.navigation.create_profile_applicant') }}
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'dashboard' })" v-if="!isApplicantAccount">
          <span class="icon-wrap"><font-awesome-icon icon="tachometer-alt"/></span>
          {{ $t('components.navigation.dashboard') }}
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'account' })" v-if="isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
          {{ $t('components.navigation.account') }}
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'company-received-applications' })" v-if="isCompanyAccount" class="d-none d-lg-block">
          <span class="icon-wrap"><font-awesome-icon icon="id-card"/></span>
          {{ $t('components.navigation.company.applications') }}
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'bookmarks' })" v-if="isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
          {{ $t('components.navigation.bookmarks') }}
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'messages' })">
          <span class="icon-wrap has-new-messages" :title="$tc('user.menu.new_messages', messageCount)" v-if="messageCount">
          <font-awesome-icon icon="envelope"/>
          </span>
          <span class="icon-wrap" v-else>
            <font-awesome-icon icon="envelope"/>
          </span>
          {{ $t('components.navigation.messages') }}
        </b-nav-item>
        <!--
        <b-nav-item :to="appLocalizeRoute({ name: 'applicantsearchprofiles' })" v-if="isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
          {{ $t('components.navigation.search_profiles') }}
        </b-nav-item>
        -->
        <b-nav-item :to="appLocalizeRoute({ name: 'main-applicant-profile' })" v-if="isApplicantAccount && !applicantSettings.allowMultipleApplications">
          <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
          <span>{{ $t('components.navigation.profile_applicant') }}</span>
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'applicant-list-own-applications' })" v-if="isApplicantAccount && applicantSettings.allowMultipleApplications">
          <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
          <span>{{ $t('components.navigation.profiles_applicant') }}</span>
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'company-edit-own-profile' })" v-if="isGrantedCompanyProfile">
          <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
          <span>{{ $t('components.navigation.profile_company') }}</span>
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'bookmarks' })" v-if="!isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
          {{ $t('components.navigation.bookmarks') }}
        </b-nav-item>
        <!--
        <b-nav-item :to="appLocalizeRoute({ name: 'companyprofiles' })" v-if="isApplicantAccount">
          <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
          {{ $t('Suchaufträge') }}
        </b-nav-item>
        -->
        <b-nav-item :href="backendUrl" v-if="isAdminAccount">
          <span class="icon-wrap"><font-awesome-icon icon="user-cog"/></span>
          {{ $t('components.navigation.backend') }}
        </b-nav-item>
        <b-nav-item :to="appLocalizeRoute({ name: 'account' })" v-if="!isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
          {{ $t('components.navigation.account') }}
        </b-nav-item>
        <b-nav-item :href="backendUrlContactsCompany" @click="onBackendClick" v-if="isAdminAccount">
          <span class="icon-wrap"><font-awesome-icon icon="info-circle"/></span>
          {{ $t('components.navigation.contacts_company') }}
        </b-nav-item >
        <b-nav-item :to="appLocalizeRoute({ name: 'company-profile-contacts', params: { companyId: activeAccount.companyId } })" v-else-if="isCompanyAccount">
          <span class="icon-wrap"><font-awesome-icon icon="id-badge"/></span>
          {{ $t('components.navigation.company.contacts') }}
        </b-nav-item >
        <b-nav-item :to="appLocalizeRoute({ name: 'contact' })">
          <span class="icon-wrap"><font-awesome-icon icon="info-circle"/></span>
          {{ $t('components.navigation.contact') }}
        </b-nav-item>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CompanyPermissionsMixin from '@/mixins/companyPermissions';

  export default {
    mixins: [CompanyPermissionsMixin],
    props: {
      messageCount: {
        type: Number,
        required: false,
        default: 0,
      },
      profileImage: {
        type: [Boolean, String],
        required: false,
        default: false,
      }
    },
    computed: {
      ...mapGetters(['activeAccount', 'isCompanyAccount', 'isApplicantAccount', 'isAdminAccount', 'applicantSettings']),
      backendUrl: function () {
        const apiUrl = process.env.API_URL ? process.env.API_URL : '';
        return apiUrl.replace(new RegExp('/api$'), '/admin');
      },
      backendUrlContactsCompany: function () {
        return this.backendUrl + '/brainappeal/campusmatchcompany/company/list';
      },
    },
    methods: {
      ...mapActions(['logout']),
      onBackendClick: function () {
        window.open(this.backendUrl, '_blank').focus();
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .user-menu {
    background-color: $gray-100;
    padding: 65px 0 5px 0;
    @media (min-width: 320px) {
      padding-top: 15px;
    }

    .user-info {
      font-size: 1rem;
      line-height: 1.5;
      display: flex;
      @media (min-width: 320px) {
        padding-right: 70px;
      }
      @include media-breakpoint-up(sm) {
        // reset padding set by nav-container
        margin-left: -21px;
      }
      h5 {
        font-size: 1rem;
        font-weight: $ba-font-weight-medium;
        line-height: 1.5;
        margin: 0;
      }
      .user-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 75px;
        text-align: center;
        color: white;
        border-radius: 1rem;
        height: 75px;
        width: 75px;
        background-color: $ba-stormy-blue;
        svg {
          margin: 0;
        }
      }

      .user-data {
        margin-left: 1rem;
        margin-top: 9px;
      }
    }

    .user-links {
      display: block;
      font-weight: $ba-font-weight-medium;
      letter-spacing: .9px;
      margin: 1.5rem 0 0 0;
      width: 100%;
      text-transform: uppercase;
    }
    svg {
      display: inline-block;
      margin-right: 14px;
    }
    .icon-wrap {
      display: inline-block;
      width: 26px;
      svg {
        margin-right: 0;
      }
    }
    .has-new-messages {
      @include ba-circle-bg($ba-red, 6px, 1px, 10px);
    }

    @include media-breakpoint-up(lg) {
      display: none;
      visibility: hidden;
    }
  }
</style>

<i18n src="@/translations/navigation.i18n.yaml" lang="yaml"/>
