import Vue from 'vue';
import LangRouter from 'vue-lang-router';
import localizedURLs from '../translations/routes';
import store from '@/store';

import Routes from '@/router/routes';

const translations = {
  de: {
    name: 'Deutsch',
    messages: {
      hello: 'Hallo!',
    },
  },
  en: {
    name: 'English',
    messages: {
      hello: 'Hello!',
    },
  },
};

Vue.use(LangRouter, {
  defaultLanguage: process.env.VUE_APP_I18N_LOCALE || 'de',
  translations,
  localizedURLs,
  i18nOptions: {
    silentFallbackWarn: process.env.NODE_ENV === 'production',
  }
});

const router = new LangRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: Routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    }
    let position = savedPosition || { x: 0, y: 0 };

    const forceScrollTopRoutes = ['companysearch', 'applicantsearch', 'application-search', 'applicantsearchprofile'];
    if (forceScrollTopRoutes.includes(to.name)) {
      position = { x: 0, y: 0 };
    }

    return new Promise((resolve, reject) => {
      const timeout = (position.x > 0 || position.y > 0) ? 500 : 0;
      setTimeout(() => {
        resolve(position);
      }, timeout);
    });
  },
});

router.beforeEach(async function (to, from, next) {
  if (to.meta && to.meta.public) {
    // public route: confirm navigation request
    next();
  } else {
    // non-public route: check user auth defaultState
    // user isAuthenticated: confirm navigation request
    if (store.getters.isAuthenticated) {
      let isGranted = true;
      // check if this route enforces special account type
      if (to.meta.only_for) {
        const getterMethods = {
          applicant: 'isApplicantAccount',
          company: 'isCompanyAccount',
          admin: 'isAdminAccount',
        };
        const checkTypes = to.meta.only_for.split(',');
        isGranted = false;
        for (let i = 0; i < checkTypes.length; i++) {
          const getter = getterMethods[checkTypes[i]];
          isGranted = isGranted || store.getters[getter];
          if (isGranted) {
            break;
          }
        }
      }
      if (to.meta.permission) {
        isGranted = isGranted && store.getters.isGranted(to.meta.permission);
      }

      if (isGranted) {
        next();
        return;
      }
    } else {
      // add forced-redirection notification
      store.dispatch('showNotification', {
        title: 'Need to login',
        desc: 'Please login first',
        type: 'warning',
      });
      console.debug('TODO open login form via EventBus');
      // TODO open login form via EventBus
    }
    // non-public route: force redirect to home route
    next({
      name: 'home',
    });
  }
});

router.afterEach(() => {
  store.dispatch('updateStaticData');
});

export default router;
